import React, { useCallback, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { PaperWhite } from "../../components/Papers";
import { FormCreate } from "../../components/Forms/Employer/FormCreate";
import { useCreateProvider } from "../../services/hooks/Providers/useCreateProviders";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert } from "../../components/Alert";
import { employeeType } from "../../constants/employeeType";
import { useMyProvider } from "../../services/hooks/Providers/useMyProvider";
import { useAuth } from "../../hook/useAuth";
import { contractType } from "../../constants/contractType";
import { validateGroupedDocuments } from "../../v2/helper/documents";
import { useGetDocumentsByEntity } from "../../services/hooks/Docs/S3/useGetDocumentsByEntity";
import { vacancyType } from "../../constants/vacancyType";
import { useUpdateDocuments } from "../../services/hooks/Docs/S3/useUpdateDocument";
import { useUploadDocuments } from "../../services/hooks/Docs/S3/useUploadDocument";
import { useApi } from "../../services/api";

const initialAlertError = {
  open: false,
  title: "",
  messages: [],
  content: "",
};

export default function CreateProfile() {
  const { email, firstname, lastname } = useAuth();
  const session = useAuth();
  const { mutate, isLoading } = useCreateProvider();
  const { setEmployeeAuthorization } = useApi(false);
  const [alertError, setAlertError] = useState(initialAlertError);
  const navigate = useNavigate();
  const { data: myProvider, isLoading: isProviderLoading } =
    useMyProvider(email);
  const [searchParams] = useSearchParams();
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const redirect = searchParams.get("navigate");
  const { data: documentsFromBucket, isLoading: isGettingDocuments } =
    useGetDocumentsByEntity(myProvider?.id, "employee");
  const { mutate: mutateUpdateDocument, isLoading: isLoadingUpdate } =
    useUpdateDocuments();
  const { mutate: mutateUploadDocument, isLoading: isLoadingUpload } =
    useUploadDocuments();

  const formValues = useMemo(
    () => ({
      providerRut: myProvider?.numberRut ?? "",
      providerName: myProvider?.name ?? "",
      providerLastName: myProvider?.lastname1 ?? "",
      providerEmail: email,
      providerPhone: myProvider?.phone ?? "",
      providerEmailVerification: "",
      legalRepresentativeDni: myProvider?.legalRepresentative?.dni ?? "",
      legalRepresentativeName:
        myProvider?.legalRepresentative?.name ?? firstname ?? "",
      legalRepresentativeLastName:
        myProvider?.legalRepresentative?.lastname ?? lastname ?? "",
      legalRepresentativeEmail:
        myProvider?.legalRepresentative?.email ?? email ?? "",
      legalRepresentativeEmailVerification: "",
      streetProvider: myProvider?.address?.street ?? "",
      numberProvider: myProvider?.address?.number ?? "",
      cityProvider: myProvider?.address?.city ?? "",
      zipCodeProvider: myProvider?.address?.zipCode ?? "",
      communeProvider: myProvider?.address?.commune ?? "",
      geoReferenceProvider: {
        lat: myProvider?.address?.geoReference?.lat ?? null,
        lng: myProvider?.address?.geoReference?.lng ?? null,
      },
    }),
    [myProvider, email, firstname, lastname]
  );

  const handleConfirmation = () => {
    setAlertError({
      ...alertError,
      open: false,
    });
  };

  const handleFinishAction = () => {
    navigate(`/${redirect && redirect !== "/" ? redirect : "profile/my-data"}`);
  };

  const isEditing = myProvider?.id ? true : false;

  const handleSubmit = useCallback(
    async (provider) => {
      const data = {
        name: provider.providerName,
        lastname1: "Business", // remove when backend has optional lastname1
        numberRut: provider.providerRut,
        phone: provider.providerPhone,
        email: provider.providerEmail,
        address: {
          street: provider.streetProvider,
          street2: "",
          number: provider.numberProvider,
          city: provider.cityProvider,
          commune: provider.communeProvider,
          zipCode: provider.zipCodeProvider,
          geoReference: provider.geoReferenceProvider,
          region: provider.regionProvider,
        },
        contractType: contractType.LABORAL,
        type: employeeType.PROVIDER,
        subcontract: false,
        legalRepresentative: {
          name: provider.legalRepresentativeName,
          dni: provider.legalRepresentativeDni,
          email: provider.legalRepresentativeEmail,
          lastname: provider.legalRepresentativeLastName,
        },
      };
      const { error: validationGroupedError, groupedDataByDocumentType } =
        validateGroupedDocuments(
          currentTemplate.documentTypes,
          provider,
          formValues
        );

      if (validationGroupedError) {
        setAlertError({
          ...alertError,
          open: true,
          title: "Error",
          messages: validationGroupedError.messages,
        });
        return;
      }

      mutate(
        {
          data,
          providerId: myProvider?.id,
        },
        {
          onSuccess: (createdProvider) => {
            if (isEditing) {
              setAlertError({
                ...alertError,
                open: true,
                title: "Felicidades",
                messages: ["Representante legal actualizado correctamente"],
              });
            }
            for (let [key, documentToUpload] of Object.entries(
              groupedDataByDocumentType
            )) {
              const beforeUpload = Object.assign({}, documentToUpload);
              beforeUpload["ownerId"] = createdProvider?.id;
              beforeUpload["ownerType"] = vacancyType.EMPLOYEE;
              beforeUpload.metaData = JSON.stringify(beforeUpload.metaData);

              if (isEditing) {
                if (!beforeUpload.file.some((docFile) => docFile === null)) {
                  const currentMetadataGroupName =
                    documentToUpload.metaData[0]?.groupName;
                  const relatedDocument = documentsFromBucket.find(
                    (relDoc) =>
                      relDoc.files[0].groupName === currentMetadataGroupName
                  );
                  const relatedDocumentId = relatedDocument?.id;

                  mutateUpdateDocument(
                    {
                      documentId: relatedDocumentId,
                      documents: beforeUpload,
                    },
                    {
                      onSuccess: () => {},
                      onError: () => {
                        setAlertError({
                          ...alertError,
                          open: true,
                          title: "Error",
                          messages: [
                            "Oh no! se ha producido un error al actualizar los documentos",
                          ],
                        });
                      },
                    }
                  );
                }

                if (
                  key ===
                  Object.keys(groupedDataByDocumentType)[
                    Object.keys(groupedDataByDocumentType).length - 1
                  ]
                ) {
                  handleFinishAction();
                }
              } else {
                mutateUploadDocument(beforeUpload, {
                  onSuccess: () => {
                    if (
                      key ===
                      Object.keys(groupedDataByDocumentType)[
                        Object.keys(groupedDataByDocumentType).length - 1
                      ]
                    ) {
                      handleFinishAction();
                    }
                  },
                  onError: () => {
                    setAlertError({
                      ...alertError,
                      open: true,
                      title: "Error",
                      messages: [
                        "Oh no! se ha producido un error al cargar los documentos",
                      ],
                    });
                  },
                });
              }
            }
            setEmployeeAuthorization({
              id: createdProvider?.id,
              name: createdProvider?.name
                ? createdProvider?.name
                : session?.firstname,
              lastname1: createdProvider?.lastname1
                ? createdProvider?.lastname1
                : session?.lastname,
              email: createdProvider?.email
                ? createdProvider?.email
                : session?.username,
              numberRut: createdProvider?.numberRut,
              status: createdProvider?.status,
              phone: createdProvider?.phone,
            });
          },
          onError: () => {
            setAlertError({
              ...alertError,
              open: true,
              title: "Error",
              messages: ["Oh no! se ha producido un error al crear proveedor"],
            });
          },
        }
      );
    },
    [myProvider?.id, redirect, currentTemplate]
  );

  if (isProviderLoading) return <LoadingSpinner open />;
  return (
    <>
      <Stack spacing={2}>
        <PaperWhite>
          <FormCreate
            formValues={formValues}
            isEditing={isEditing}
            onSubmit={handleSubmit}
            setTemplate={setCurrentTemplate}
          />
        </PaperWhite>
        {(isLoading ||
          isLoadingUpload ||
          isLoadingUpdate ||
          isGettingDocuments) && <LoadingSpinner open />}
      </Stack>
      <Alert
        icon
        open={alertError.open}
        handleConfirmation={handleConfirmation}
        title={alertError.title}
        messages={alertError.messages}
      />
    </>
  );
}
