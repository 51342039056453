import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import { useListEmployee } from "../../../services/hooks/Employee/useListEmployee";
import { Table } from "../../../components/Table";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { maskRUT } from "../../../../utils";
import { ActiveStatus, EmployeeStatus } from "../../../../constants/status";
import ListDocumentsModal from "../../../components/Modal/ListDocumentModal";
import { vacancyType } from "../../../../constants/vacancyType";
import { headCellsEmployeeBackOffice } from "../../../helper/headcell";
import { TabAddonsList } from "../../../components/TabsAddon";
import { useURLfilters } from "../../../hooks/filters.hook";
import { TableButton } from "../../../components/Button/TableButton";
import { ReactComponent as EditButtonIcon } from "../../../assets/images/editbutton.svg";
import { ReactComponent as InfoButton } from "../../../assets/images/infobutton.svg";
import { useReportEmployee } from "../../../services/hooks/Employee/useReportEmployees";
import { urlDownload } from "../../../helper/files";
import { AlertSnackBar } from "../../../../components/Alerts";

export default function ListEmployee({ type }) {
  const navigate = useNavigate();
  const defaultFilters = {
    type: type,
    status: EmployeeStatus.APPROVED,
    page: 1,
    limit: 10,
  };

  const [item, setItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [error, setError] = useState({ isOpen: false });
  const { mutate: mutateReportEmployee, isLoading: isLoadingReport } =
    useReportEmployee();

  const { filters, setFilters, cleanFilters, getURLValue } =
    useURLfilters(defaultFilters);
  const { data, isLoading } = useListEmployee(filters);

  const onChangeFilters = (newFilter = {}) => {
    setFilters({ ...filters, ...newFilter });
  };

  const { TableContainer, setTableLoading, setPage } = Table(
    headCellsEmployeeBackOffice,
    {
      ...data?.metadata,
      page: getURLValue("page", 1),
      limit: getURLValue("limit", 10),
    },
    onChangeFilters
  );

  useEffect(() => {
    setTableLoading(isLoading);
    if (data) {
      setDownloadData(
        data.data.map((item) => {
          return {
            numberRut: item.numberRut,
            name: item.name
              .concat(" ")
              .concat(item.lastname1)
              .concat(" ")
              .concat(item?.lastname2 ?? ""),
            email: item.email,
            numberRutProvider: item.provider?.numberRut ?? "",
            nameProvider: item.provider?.name ?? "",
            active: item?.active?.isActive
              ? ActiveStatus.ACTIVE
              : ActiveStatus.INACTIVE,
            blocked: item.blocked.isBlocked ? "SI" : "NO",
          };
        })
      );
    }
  }, [data, isLoading]);

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    setFilters({ search: newValue, page: 1 });
    setPage(0);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onChangeBlocked = (event) => {
    onChangeFilters({ isBlocked: event.target.value, page: 1 });
  };

  const onChangeActive = (event) => {
    onChangeFilters({ isActive: event.target.value, page: 1 });
  };

  const onEditDocument = (item) => {
    navigate(`/employee/edit/${item.id}`);
  };

  const handleDownloadReport = () => {
    mutateReportEmployee(filters, {
      onSuccess: (filePath) => {
        urlDownload(filePath);
      },
      onError: () => {
        setError({
          isOpen: true,
          title: "Error",
          message:
            "Hubo un error al descargar el reporte de flotas. Inténtelo nuevamente.",
          handleClose: () => setError({ isOpen: false }),
        });
      },
    });
  };

  const TabsAddons = () => (
    <TabAddonsList
      search={getURLValue("search", "")}
      clearFilters={cleanFilters}
      active={getURLValue("isActive", "")}
      blocked={getURLValue("isBlocked", false)}
      downloadData={downloadData}
      handleSearch={handleSearch}
      onChangeActive={onChangeActive}
      onChangeBlocked={onChangeBlocked}
      headCell={headCellsEmployeeBackOffice}
      nameFile={type}
      handleDownloadReport={handleDownloadReport}
    />
  );

  if (isLoading || isLoadingReport) return <LoadingSpinner open />;

  return (
    <Stack spacing={2}>
      <TableContainer addons={[TabsAddons]} withPagination>
        <TableBody>
          {data?.data.map((employee) => (
            <TableRow hover tabIndex={-1} key={employee.id}>
              <TableCell>{maskRUT(employee.numberRut)}</TableCell>
              <TableCell>
                {`${employee.name} ${employee?.lastname1 ?? ""} ${
                  employee?.lastname2 ?? ""
                }`.trim()}
              </TableCell>
              <TableCell sx={{ opacity: 1 }}>{employee.email}</TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {employee.provider
                  ? maskRUT(employee.provider?.numberRut)
                  : "-"}
              </TableCell>
              <TableCell>
                {`${employee.provider?.name ?? "-"}`.trim()}
              </TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {employee?.active?.isActive
                  ? ActiveStatus.ACTIVE
                  : ActiveStatus.INACTIVE}
              </TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {employee.blocked.isBlocked ? "SI" : "NO"}
              </TableCell>
              <TableCell>
                <Stack direction="row">
                  <TableButton onClick={() => onEditDocument(employee)}>
                    <EditButtonIcon />
                  </TableButton>
                  <TableButton
                    onClick={() => {
                      setItem(employee);
                      setShowModal(true);
                    }}
                  >
                    <InfoButton />
                  </TableButton>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
      <AlertSnackBar
        open={error.isOpen}
        message={error.message}
        handleClose={error.handleClose}
      />
      <ListDocumentsModal
        isOpen={showModal}
        onClose={handleCloseModal}
        type={vacancyType.EMPLOYEE}
        item={item}
      />
    </Stack>
  );
}
